<template>
<div class="administrationgoogleplaces-container">
    <slideout-panel></slideout-panel>
    <DialogWrapper v-if="dialogComponent != null" :component="dialogComponent" :callback="dialogCallback" :is-mobile="false" :data="dialogData" :preventClose="true" @close="dialogComponent = null" />
    <vs-row justify="space-between" style="width:100%;">
        <vs-button size="large" border @click="back">Indietro</vs-button>
        <vs-button size="large" @click="search">Cerca</vs-button>
    </vs-row>
    <vs-table v-model="selected" style="height: 80vh; width: 100%; margin-top: 20px;">
        <template #thead>
            <vs-tr>
                <vs-th v-if="inputPlaces != null">
                    POI
                </vs-th>
                <vs-th>
                    Nome
                </vs-th>
                <vs-th>
                    Indirizzo
                </vs-th>
                <vs-th>
                    Categoria
                </vs-th>
                <vs-th>
                    Foto
                </vs-th>
            </vs-tr>
        </template>
        <template #tbody>
            <vs-tr :key="i" v-for="(tr, i) in places" :data="tr" :is-selected="selected == tr">
                <vs-td v-if="inputPlaces != null">
                    {{ tr.localized_name }}
                </vs-td>
                <vs-td>
                    {{ tr.display_name }}
                </vs-td>
                <vs-td>
                    {{ tr.formatted_address }}
                </vs-td>
                <vs-td>
                    {{ tr.primary_type_display_name }}
                </vs-td>
                <vs-td>
                    <vs-button :disabled="tr.photo_names == null || tr.photo_names.length == 0" @click="select(tr)">
                        {{ tr.photo_names ? tr.photo_names.length : 0}} Foto
                    </vs-button>
                </vs-td>
            </vs-tr>
        </template>

    </vs-table>
</div>
</template>

<script>
import DialogWrapper from '../../components/DialogWrapper.vue';
import GooglePlaces from '../../components/elements/GooglePlaces';
import GooglePlacesNearby from '../../components/dialogs/GooglePlacesNearby.vue';
export default ({
    data() {
        return {
            places: [],
            selected: null,
            panel: null,

            dialogComponent: null,
            dialogCallback: null,
            dialogData: null,
        }
    },
    props: {
        user: {
            type: Object,
            required: false
        },

        inputPlaces: {
            type: Array,
            required: false
        }
    },

    mounted() {
        if (this.inputPlaces) {
            this.places = this.inputPlaces;
        }
    },

    methods: {
        back() {
            this.$router.push({
                name: 'ADM_POI',
                params: {
                    oldFilters: this.$route.params.poisAdminFilters,
                    oldPoi: this.$route.params.poiAdmin
                }
            })
        },

        search(){
            this.dialogCallback = (data) => {
                this.places = data;
                this.dialogComponent = null;
            }
            this.dialogData = {};
            this.dialogComponent = GooglePlacesNearby;
        },

        async select(n) {
            var vm = this;

            if (this.panel != null) {
                await this.panel.hide();
                this.panel = null;
            }

            if (n == null) {
                return;
            }
            this.panel = await this.$showPanel({
                component: GooglePlaces,
                openOn: 'right',
                props: {
                    googlePlace: n,
                }
            });
            console.log(this.panel)

            this.panel.promise
                .then(() => {
                    vm.selected = null;
                });
        }

    },

    watch: {

    },

    components: {
        DialogWrapper,
    }
})
</script>

<style scoped>
.administrationgoogleplaces-container {
    padding-left: 70px;
    padding-right: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    box-sizing: border-box;
}
</style>
